import {Layout as AntdLayout, FloatButton, theme} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {Outlet, useMatches} from 'react-router-dom';
import {Suspense, useMemo, useState} from 'react';
import styled, {css} from 'styled-components';

import Footer from '../components/Footer';
import Header from '../components/AppHeader';
import Layout from './Layout';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Progress from '../components/Progress';
import SiderLayout from '../components/SiderLayout';

const MenuSider = styled(AntdLayout.Sider)`
    border-right: ${props => props.theme.lineWidth}px ${props => props.theme.lineType}
        ${props => props.theme.colorSplit};
    overflow: auto;
    height: 100vh;
    left: 0;
    z-index: 9;
    position: fixed !important;

    .ant-menu-inline {
        border-right-color: transparent !important;
    }
`;

const LogoWrapper = styled.div`
    text-align: center;
    margin: 7px auto;
`;

const StyledMenu = styled(Menu)`
    margin-top: 20px;
`;

const ContentLayout = styled(AntdLayout)`
    margin-left: ${props => (props.$broken ? '0' : props.theme.Layout.menuSiderWidth)}px;
    position: relative;
`;

const ContentHeader = styled(Header)`
    width: calc(100vw - ${props => props.theme.Layout.menuSiderWidth}px) !important;
    ${props =>
        props.$broken
            ? css`
                  width: 100vw !important;
                  padding-inline: 0 !important;
              `
            : css`
                  padding-inline: 20px !important;
              `}
    ${props =>
        props.$collapsed
            ? css`
                  .user-menu .ant-menu-title-content {
                      display: none;
                  }
              `
            : ''}
`;

const ContentFooter = styled(Footer)`
    margin-right: ${props => props.$marginRight}px;
`;

const BackTop = styled(FloatButton.BackTop)`
    right: ${props => props.$right}px;
`;

export default function AppLayout() {
    const matches = useMatches();
    const {sider, siderWidth, noBackTop} = useMemo(() => matches[1]?.data.layoutProps ?? {}, [matches]);

    const [collapsed, setCollapsed] = useState(false);
    const [broken, setBroken] = useState(false);

    const contentSiderWidth = useMemo(() => siderWidth ?? 300, [siderWidth]);
    const [contentCollapsed, setContentCollapsed] = useState(false);
    const [contentBroken, setContentBroken] = useState(false);
    const contentRight = useMemo(
        () => (!sider || contentBroken ? 0 : contentSiderWidth),
        [contentBroken, contentSiderWidth, sider]
    );
    const backTopRight = useMemo(
        () => (!sider || contentCollapsed ? 50 : contentSiderWidth + 50),
        [contentCollapsed, sider, contentSiderWidth]
    );

    const {token} = theme.useToken();

    return (
        <Layout>
            <MenuSider
                breakpoint="md"
                trigger={null}
                width={token.Layout.menuSiderWidth}
                collapsed={collapsed}
                collapsible
                collapsedWidth={0}
                onCollapse={value => setCollapsed(value)}
                onBreakpoint={setBroken}
            >
                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
                <StyledMenu />
            </MenuSider>
            <ContentLayout $broken={broken}>
                <ContentHeader
                    $broken={broken}
                    $collapsed={collapsed}
                    menuTrigger={
                        broken ? (
                            <span onClick={() => setCollapsed(v => !v)}>
                                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            </span>
                        ) : null
                    }
                    showLogo={broken}
                />
                <SiderLayout
                    sider={sider}
                    siderWidth={siderWidth}
                    onCollapse={collapsed => setContentCollapsed(collapsed)}
                    onBreakpoint={setContentBroken}
                >
                    <Suspense fallback={<Progress />}>
                        <Outlet />
                    </Suspense>
                </SiderLayout>
                {noBackTop ? null : <BackTop $right={backTopRight} />}
                <ContentFooter $marginRight={contentRight} />
            </ContentLayout>
        </Layout>
    );
}
