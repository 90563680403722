import {useCallback, useMemo, useRef, useState} from 'react';

import Content from './Content';
import {Provider as ContentSiderProvider} from '../context/ContentSiderContext';
import {Layout} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import styled from 'styled-components';

const StyledContent = styled(Content)`
    margin-right: ${props => props.$marginRight}px;
    padding: 0 20px;
    min-height: calc(
        100vh - ${props => props.theme.Layout.layoutFooterHeight}px -
            ${props => props.theme.Layout.layoutHeaderHeight}px
    ) !important;
`;

const Aside = styled(Layout.Sider)`
    height: calc(100vh - ${props => props.theme.Layout.layoutHeaderHeight}px);
    overflow: auto;
    position: fixed !important;
    top: ${props => props.theme.Layout.layoutHeaderHeight}px;
    right: 0;
`;

const AsideContent = styled.div`
    padding: 1.5rem;
`;

const AsideTrigger = styled.div`
    position: fixed;
    top: 84px;
    right: ${props => props.$right}px;
    background-color: rgba(0, 0, 0, 0.45);
    color: #fff;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    transition: right 0.2s ease, background-color 0.3s ease;

    &:hover {
        background-color: #000;
    }
`;

export default function SiderLayout({sider, siderWidth, onCollapse, onBreakpoint, children}) {
    const siderRef = useRef(null);

    const [collapsed, setCollapsed] = useState(false);
    const [broken, setBroken] = useState(false);

    const contentMarginRight = useMemo(() => (!sider || broken ? 0 : siderWidth ?? 300), [broken, sider, siderWidth]);
    const currentSiderWidth = useMemo(
        () => (!sider || collapsed ? 0 : siderWidth ?? 300),
        [collapsed, sider, siderWidth]
    );

    const onCollapseChange = useCallback(
        (collapsed, type) => {
            setCollapsed(collapsed);
            onCollapse?.(collapsed, type);
        },
        [onCollapse]
    );
    const onBreakpointChange = useCallback(
        broken => {
            setBroken(broken);
            onBreakpoint?.(broken);
        },
        [onBreakpoint]
    );

    return (
        <Layout className={sider ? 'layout-with-sider' : ''}>
            <StyledContent $marginRight={contentMarginRight}>
                <ContentSiderProvider siderRef={siderRef}>{children}</ContentSiderProvider>
            </StyledContent>
            {sider ? (
                <>
                    <Aside
                        breakpoint="md"
                        trigger={null}
                        collapsed={collapsed}
                        collapsible
                        collapsedWidth={0}
                        reverseArrow
                        onCollapse={onCollapseChange}
                        onBreakpoint={onBreakpointChange}
                        width={currentSiderWidth}
                    >
                        <AsideContent ref={siderRef}></AsideContent>
                    </Aside>
                    {broken ? (
                        <AsideTrigger onClick={() => setCollapsed(v => !v)} $right={currentSiderWidth}>
                            <SettingOutlined />
                        </AsideTrigger>
                    ) : null}
                </>
            ) : null}
        </Layout>
    );
}
